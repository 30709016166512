import Cookies from "universal-cookie";

export class ApiResponseError extends Error {
  constructor(response, ...args) {
    super("Api response error", ...args);
    this.name = "ApiResponseError";
    this.body = response.body;
    this.status = response.status;
  }
}
const parseResponse = async (response) => ({
  ok: response.ok,
  status: response.status,
  body: await response.json(),
});

export const patchJsonRequest = async ({ url, body }) => {
  const cookies = new Cookies();
  const myHeaders = new Headers({
    "X-CSRF-TOKEN": cookies.get("XSRF-TOKEN"),
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json",
  });
  const myInit = {
    method: "PATCH",
    headers: myHeaders,
    mode: "cors",
    credentials: "include",
    cache: "default",
    body: JSON.stringify(body),
  };
  const response = await fetch(url, myInit);
  const parsedResponse = await parseResponse(response);
  if (!response.ok) {
    throw new ApiResponseError(parsedResponse);
  }
  return parsedResponse;
};

export const postJsonRequest = async ({ url, body }) => {
  const cookies = new Cookies();
  const myHeaders = new Headers({
    "X-CSRF-TOKEN": cookies.get("XSRF-TOKEN"),
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json",
  });
  const myInit = {
    method: "POST",
    headers: myHeaders,
    mode: "cors",
    credentials: "include",
    cache: "default",
    body: JSON.stringify(body),
  };
  const response = await fetch(url, myInit);
  const parsedResponse = await parseResponse(response);
  if (!response.ok) {
    throw new ApiResponseError(parsedResponse);
  }
  return parsedResponse;
};
