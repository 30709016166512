import { terminalIntegration_options } from "./options";

export const venueName = {
  id: "venue_name",
  getProps: (t) => ({
    question: {
      label: t("venueName.intro"),
    },
  }),
  getNextQuestion: () => countryCode,
};

export const countryCode = {
  id: "country_code",
  getProps: (t) => ({
    question: {
      label: t("countryCode.intro"),
    },
    answers: {
      au: {
        value: "AU",
        label: t("countryCode.au"),
      },
      nz: {
        value: "NZ",
        label: t("countryCode.nz"),
      },
      gb: {
        value: "GB",
        label: t("countryCode.gb"),
      },
    },
  }),
  getNextQuestion: () => styleOfBusiness,
  getPreviousQuestion: () => venueName.id,
};

export const styleOfBusiness = {
  id: "style_of_business",
  getProps: (t, venueName) => {
    return {
      question: {
        label: t("styleOfBusiness.intro", venueName),
      },
      answers: {
        cafe: {
          value: "cafe",
          label: t("styleOfBusiness.cafe"),
        },
        restaurant: {
          value: "restaurant",
          label: t("styleOfBusiness.restaurant"),
        },
        bar: {
          value: "bar",
          label: t("styleOfBusiness.bar"),
        },
        takeAway: {
          value: "take_away",
          label: t("styleOfBusiness.takeAway"),
        },
        foodTruck: {
          value: "food_truck",
          label: t("styleOfBusiness.foodTruck"),
        },
        pub: {
          value: "pub",
          label: t("styleOfBusiness.pub"),
        },
        other: {
          value: "other",
          label: t("styleOfBusiness.other"),
        },
      },
    };
  },
  getNextQuestion: () => serviceStyle,
  getPreviousQuestion: () => countryCode.id,
};

export const serviceStyle = {
  id: "service_set_up",
  getProps: (t) => ({
    question: {
      label: t("serviceStyle.intro"),
    },
    answers: {
      coffeeCabinet: {
        // Small/Medium
        value: "counter_service",
        label: t("serviceStyle.coffeeCabinet"), //Coffee + cabinet only
      },
      takeaways: {
        // Small/Medium
        value: "takeaways_only",
        label: t("serviceStyle.takeaways"), //Mostly takeaways but I have a kitchen
      },
      fullService: {
        // Medium
        value: "full_table_service",
        label: t("serviceStyle.full"), //Full service style
      },
      large: {
        value: "large_venue",
        label: t("serviceStyle.large"), //We’re a large venue with 5 or more POS stations
      },
    },
  }),
  getNextQuestion: () => menuItems,
  getPreviousQuestion: () => styleOfBusiness.id,
};

export const menuItems = {
  id: "menu_size",
  getProps: (t) => ({
    question: {
      label: t("menuItems.intro"),
    },
    answers: {
      aFew: {
        // Small/Medium
        value: "small",
        label: t("menuItems.aFew"), //Less than 40
      },
      medium: {
        value: "medium",
        label: t("menuItems.medium"), //More than 40, less than 100
      },
      heaps: {
        value: "large",
        label: t("menuItems.heaps"),
      },
    },
  }),
  getNextQuestion: () => controlFeatures,
  getPreviousQuestion: () => serviceStyle.id,
};

export const controlFeatures = {
  id: "interested_features",
  getProps: (t) => ({
    question: {
      label: t("controlFeatures.intro"),
    },
    answers: {
      tableLayout: {
        value: "table_layout",
        label: t("controlFeatures.tableLayout"),
      },
      accounts: {
        value: "accounts",
        label: t("controlFeatures.accounts"),
      },
      tillLogins: {
        value: "till_logins",
        label: t("controlFeatures.tillLogins"),
      },
      stockControl: {
        value: "stock_control",
        label: t("controlFeatures.stockControl"),
      },
      menuCostings: {
        value: "menu_costings",
        label: t("controlFeatures.menuCostings"),
      },
      wastage: {
        value: "wastage",
        label: t("controlFeatures.wastage"),
      },
      refund: {
        value: "refund",
        label: t("controlFeatures.refund"),
      },
      courses: {
        value: "courses",
        label: t("controlFeatures.courses"),
      },
      custom: {
        value: "custom",
        label: t("controlFeatures.custom"),
      },
      onlineMenuPromotions: {
        value: "online_menu_promotions",
        label: t("controlFeatures.onlineMenuPromotions"),
      },
    },
  }),
  getNextQuestion: () => terminalIntegration,
  getPreviousQuestion: () => menuItems.id,
};

export const terminalIntegration = {
  id: "preferred_eftpos_provider",
  getProps: (t, { country }) => ({
    question: {
      label: t("terminalIntegration.intro"),
    },
    answers: terminalIntegration_options(t)[country],
  }),
  getNextQuestion: () => regulr,
  getPreviousQuestion: () => controlFeatures.id,
};

export const regulr = {
  id: "interested_in_regulr",
  getProps: (t) => ({
    question: {
      label: t("regulr.intro"),
    },
    answers: {
      yes: {
        value: true,
        label: t("regulr.yes"),
      },
      no: {
        value: false,
        label: t("regulr.no"),
      },
    },
  }),
  getNextQuestion: () => paperless,
  getPreviousQuestion: () => terminalIntegration.id,
};

export const paperless = {
  id: "interested_in_paperless",
  getProps: (t) => ({
    question: {
      label: t("paperless.intro"),
    },
    answers: {
      yes: {
        value: true,
        label: t("paperless.yes"),
      },
      no: {
        value: false,
        label: t("paperless.no"),
      },
    },
  }),
  getNextQuestion: () => printers,
  getPreviousQuestion: () => regulr.id,
};

export const printers = {
  id: "printer_setup",
  getProps: (t) => ({
    question: {
      label: t("printers.intro"),
    },
    answers: {
      onlyEmailReceipts: {
        value: "no_printers",
        label: t("printers.emailReceipts"),
      }, // No printer, Small
      onlyPrintReceipts: {
        value: "up_to_two_printers",
        label: t("printers.onlyPrintReceipts"),
      }, // Small
      printReceiptsAndUptoTwoDockets: {
        value: "up_to_two_printers",
        label: t("printers.printReceiptsAndUptoTwoDockets"),
      },
      multipleDocketsOnePrinter: {
        value: "more_than_two_printers",
        label: t("printers.multipleDocketsOnePrinter"),
      },
      lotsOfPrinters: {
        value: "more_than_two_printers",
        label: t("printers.lotsOfPrinters"),
      },
    },
  }),
  getNextQuestion: () => hardwareSorted,
  getPreviousQuestion: () => paperless.id,
};

export const hardwareSorted = {
  id: "hardware_sorted",
  getProps: (t) => ({
    question: {
      label: t("hardwareSorted.intro"),
    },
  }),
  getNextQuestion: ({ hardware_sorted }) => {
    return hardware_sorted ? packagePreview : hardwareForm;
  },
  getPreviousQuestion: () => printers.id,
};

export const hardwareForm = {
  id: "hardwareForm",
  questionsAddedToJourney: 1,
  getNextQuestion: () => packagePreview,
  getPreviousQuestion: () => hardwareSorted.id,
};

export const packagePreview = {
  id: "packagePreview",
  getNextQuestion: () => contactInformation,
  getPreviousQuestion: () => hardwareSorted.id,
};

export const contactInformation = {
  id: "contactInformation",
  getNextQuestion: () => packagePreview,
};
