import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "./Button";
import TitleQuestion from "./TitleQuestion";

const Checkbox = ({
  question,
  onCompletion,
  journeyProgress,
  goToPreviousQuestion,
}) => {
  const { register, watch, handleSubmit } = useForm();
  const { t } = useTranslation();

  const hasSelected = watch("interested_features")
    ? watch("interested_features").length > 0
    : false;

  const onSubmit = (response) => {
    onCompletion(response);
  };
  const { question: q, answers } = question.getProps(t);
  return (
    <div className="text-center flex-col md:text-lg text-md">
      <TitleQuestion
        question={q}
        journeyProgress={journeyProgress}
        goToPreviousQuestion={goToPreviousQuestion}
      />
      <div className="flex justify-center">
        <form className="flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="block md:p-16 p-8 md:space-y-4 space-y-2">
            {Object.values(answers).map(({ value, label }) => (
              <label className="md:text-center text-left flex items-center hover:bg-gray-200 px-4 rounded cursor-pointer">
                <input
                  type="checkbox"
                  className="form-radio align-middle mr-4"
                  key={value}
                  value={value}
                  {...register(question.id)}
                />
                {label}
              </label>
            ))}
          </div>
          <div className="flex-col flex items-center space-y-4">
            {hasSelected ? (
              <Button type="submit">{t("button.next")}</Button>
            ) : (
              <Button type="submit">{t("button.noThanks")}</Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Checkbox;
