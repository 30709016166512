import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FcOk } from "react-icons/fc";
import { FaChevronLeft } from "react-icons/fa";

import posBossLogo from "./images/posBoss.png";
import packagePageImg from "./images/packagePageImg.png";
import regulrBadge from "./images/appBadgeRegulr.png";
import paperlessBadge from "./images/appBadgePaperless.png";
import hardware from "./images/hardware.png";
import HardwareItems from "./HardwareItems";

/***
 `gatsby-plugin-google-gtag` only works in production.
 In production return gtag but if it doesn't exist return an empty function to not break api access.
 ***/
const fakeGtag = (event, end_journey, options) => {};
const getGtag = () =>
  typeof window !== "undefined" && window.gtag ? window.gtag : fakeGtag;

const getPlans = (t) => {
  const controlFeaturesList = [
    t("packagePage.controlFirst"),
    t("packagePage.controlSecond"),
    t("packagePage.controlThird"),
    t("packagePage.controlFourth"),
    t("packagePage.controlFifth"),
    t("packagePage.controlSixth"),
    t("packagePage.controlSeventh"),
    t("packagePage.controlEighth"),
    t("packagePage.controlNinth"),
  ];

  const largePlan = {
    planName: t("packagePage.largePlanName"),
    description: t("packagePage.largePlanDescription"),
    features: [
      t("packagePage.largePlanFirst"),
      t("packagePage.largePlanSecond"),
      t("packagePage.largePlanThird"),
      t("packagePage.largePlanFourth"),
      t("packagePage.largePlanFifth"),
      t("packagePage.largePlanSixth"),
      t("packagePage.largePlanSeventh"),
      t("packagePage.largePlanEighth"),
      t("packagePage.largePlanNinth"),
    ],
    badge: posBossLogo,
    price: 180,
  };
  const mediumPlan = {
    planName: t("packagePage.mediumPlanName"),
    description: t("packagePage.mediumPlanDescription"),
    features: [
      t("packagePage.mediumPlanFirst"),
      t("packagePage.mediumPlanSecond"),
      t("packagePage.mediumPlanThird"),
      t("packagePage.mediumPlanFourth"),
      t("packagePage.mediumPlanFifth"),
      t("packagePage.mediumPlanSixth"),
      t("packagePage.mediumPlanSeventh"),
      t("packagePage.mediumPlanEighth"),
      t("packagePage.mediumPlanNinth"),
    ],
    controlFeaturesList,
    badge: posBossLogo,
    price: 88,
  };
  const smallPlan = {
    planName: t("packagePage.smallPlanName"),
    description: t("packagePage.smallPlanDescription"),
    features: [
      t("packagePage.smallPlanFirst"),
      t("packagePage.smallPlanSecond"),
      t("packagePage.smallPlanThird"),
      t("packagePage.smallPlanFourth"),
      t("packagePage.smallPlanFifth"),
      t("packagePage.smallPlanSixth"),
      t("packagePage.smallPlanSeventh"),
      t("packagePage.smallPlanEighth"),
      t("packagePage.smallPlanNinth"),
    ],
    controlFeaturesList,
    badge: posBossLogo,
    price: 59,
  };
  const regulr = {
    name: t("packagePage.regulrName"),
    description: t("packagePage.regulrDescription"),
    features: [
      t("packagePage.regulrFirst"),
      t("packagePage.regulrSecond"),
      t("packagePage.regulrThird"),
      t("packagePage.regulrFourth"),
      t("packagePage.regulrFifth"),
      t("packagePage.regulrSixth"),
    ],
    badge: regulrBadge,
    price: 39,
  };
  const paperless = {
    name: t("packagePage.paperlessName"),
    description: t("packagePage.paperlessDescription"),
    features: [
      t("packagePage.paperlessFirst"),
      t("packagePage.paperlessSecond"),
      t("packagePage.paperlessThird"),
      t("packagePage.paperlessFourth"),
      t("packagePage.paperlessFifth"),
      t("packagePage.paperlessSixth"),
    ],
    badge: paperlessBadge,
    price: 39,
  };

  return {
    largePlan,
    mediumPlan,
    smallPlan,
    regulr,
    paperless,
    controlFeaturesList,
  };
};

const CallToActionButtons = ({
  preSignUpJourney,
  analyticsFinishJourney,
  sendQuote,
}) => {
  const { t } = useTranslation();

  const handleEmailQuoteClick = () => {
    sendQuote();
    analyticsFinishJourney("Email Quote", "on preview package");
  };

  return (
    <div className="mx-auto justify-center md:space-y-8 space-y-4 px-4 md:text-lg sm:text-md text-sm">
      <div className="flex-shrink-0 md:flex-row justify-center">
        <div
          className="inline-block bg-marketing-500 hover:bg-marketing-400 text-white md:py-4 py-2 md:px-8 px-4 border rounded-lg mx-4 my-2"
          onClick={() => handleEmailQuoteClick()}
        >
          {t("packagePage.emailQuote")}
        </div>

        <a
          target="_blank"
          href={`${process.env.GATSBY_POSBOSS_URL}/users/sign_up?pre_sign_up_journey_uuid=${preSignUpJourney.uuid}`}
          className="inline-block bg-sogui-500 hover:bg-opacity-75 text-white md:py-4 py-2 md:px-8 px-4 border rounded-lg mx-4 my-2"
          onClick={() => analyticsFinishJourney("Trial", "on preview package")}
        >
          {t("packagePage.trialButton")}
        </a>

        <a
          target="_blank"
          href="https://www.posbosshq.com/talk-to-us"
          className="inline-block bg-marketing-500 hover:bg-marketing-400 text-white md:py-4 py-2 md:px-8 px-4 border rounded-lg mx-4 my-2"
          onClick={() =>
            analyticsFinishJourney("Contact", "on preview package")
          }
        >
          {t("packagePage.callButton")}
        </a>
      </div>
    </div>
  );
};

const PackagePreview = ({
  preSignUpJourney,
  sendQuote,
  goToHardwareForm,
  goToPreviousQuestion,
}) => {
  const gtag = getGtag();
  const { t } = useTranslation();

  const {
    largePlan,
    mediumPlan,
    smallPlan,
    regulr,
    paperless,
    controlFeaturesList,
  } = getPlans(t);

  const venueName = preSignUpJourney.venue_name;
  const serviceSetUp = preSignUpJourney.service_set_up;
  const menuSize = preSignUpJourney.menu_size;
  const interestedInRegulr = preSignUpJourney.interested_in_regulr;
  const interestedInPaperless = preSignUpJourney.interested_in_paperless;
  const printers = preSignUpJourney.printer_setup;

  const showHardwareQuote = !preSignUpJourney.hardware_sorted;
  const countryCode = preSignUpJourney.country_code;

  const getRecommendedPlan = () => {
    if (serviceSetUp === "large_venue") {
      return largePlan;
    } else if (
      ["counter_service", "takeaways_only"].includes(serviceSetUp) &&
      ["no_printers", "up_to_two_printers"].includes(printers) &&
      menuSize == "small"
    ) {
      return smallPlan;
    }
    return mediumPlan;
  };

  const plan = getRecommendedPlan();

  const addOnList = [];
  if (interestedInRegulr) addOnList.push(regulr);
  if (interestedInPaperless) addOnList.push(paperless);

  const planPrice =
    plan.price +
    addOnList
      .map((addOn) => addOn.price)
      .reduce((prev, current) => prev + current, 0);

  const analyticsFinishJourney = (action, label) => {
    gtag("event", action.toString(), {
      event_category: "finish_journey",
      event_action: label.toString(),
    });
    return true;
  };

  useEffect(() => {
    analyticsFinishJourney("reach end screen", "on preview package");
  }, []);

  return (
    <div className="flex-col text-center">
      <div className="bg-gradient-to-r md:w-auto md:py-8 py-4 lg:px-16 px-8 from-blue-300 to-green-200 flex justify-center">
        <div
          className="absolute top-22 left-8 cursor-pointer"
          onClick={goToPreviousQuestion}
        >
          <FaChevronLeft />
        </div>

        <div className="max-w-screen-sm min-w-sm">
          <img
            className="w-1/2 mx-auto"
            src={packagePageImg}
            alt="PackagePageAvatar"
          />
          <h1 className="md:text-4xl text-2xl">{t("packagePage.weDone")}</h1>
        </div>
      </div>

      <div className="pt-8 px-8 md:text-lg text-md flex flex-col justify-center mx-auto max-w-screen-sm">
        <div>
          <Trans i18nKey={"packagePage.recommend"} values={{ venueName }} />
        </div>

        <table>
          <tbody>
            <tr>
              <td className="text-left">{plan.planName}</td>
              <td className="text-right">
                {t("packagePage.hardwarePrice", {
                  price: plan.price.toFixed(2),
                })}
              </td>
            </tr>

            {addOnList.map((addOn) => (
              <tr>
                <td className="text-left">{addOn.name}</td>
                <td className="text-right">
                  {t("packagePage.hardwarePrice", {
                    price: addOn.price.toFixed(2),
                  })}
                </td>
              </tr>
            ))}
          </tbody>

          <tfoot className="font-bold">
            <tr>
              <td className="text-left">
                {t("packagePage.subscriptionTotal")}
              </td>
              <td className="text-right">
                {t("packagePage.hardwarePrice", {
                  price: planPrice.toFixed(2),
                })}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="w-full md:p-16 py-8 px-4 space-y-8">
        <CallToActionButtons
          preSignUpJourney={preSignUpJourney}
          analyticsFinishJourney={analyticsFinishJourney}
          sendQuote={sendQuote}
        />
      </div>

      <div className="max-w-screen-xl flex-col mx-auto">
        <div className="bg-white justify-items-center xl:w-3/4 md:p-8 px-8 mx-auto">
          <div className="mx-auto space-y-8">
            <div className="border-solid border-2 border-gray-400 rounded-lg md:p-8 p-4">
              <div className="md:flex md:space-x-8 space-y-4 text-left">
                <div className="flex-none md:w-32 w-24 aspect-auto mx-auto">
                  <img src={plan.badge} alt="ProductInfo" />
                </div>

                <div className="md:space-y-8 space-y-4 w-full md:text-lg text-md">
                  <div className="md:space-y-2 space-y-1 text-center md:text-left">
                    <div className="font-bold md:text-xl text-lg">
                      {plan.planName}
                    </div>
                    <div>
                      {t("packagePage.pricePerMonth", { price: plan.price })}
                    </div>
                    <div>
                      <Trans
                        i18nKey={plan.description}
                        values={{ venueName, ...plan }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="flex flex-wrap justify-between">
                      {plan.features.map((feature) => (
                        <div className="text-left flex items-center w-full sm:w-1/2 md:w-1/3 space-x-2 md:pb-4 pb-2 leading-tight">
                          <div className="flex-none">
                            <FcOk />
                          </div>
                          <span>{feature}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div className="font-bold">
                      {t("packagePage.allOfOurPlansComeWith")}
                    </div>
                    <div className="flex flex-wrap justify-between">
                      {controlFeaturesList.map((feature) => (
                        <div className="text-left flex items-center w-full sm:w-1/2 md:w-1/3 space-x-2 md:pb-4 pb-2 leading-tight">
                          <div className="flex-none">
                            <FcOk />
                          </div>
                          <span>{feature}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-sm text-center pt-4">{t("packagePage.gst")}</p>
            </div>

            {addOnList.map((addOn) => {
              return (
                <div className="border-solid border-2 border-gray-400 rounded-lg md:p-8 p-4 md:text-lg text-md">
                  <div className="md:flex md:space-x-8 space-y-4 text-left">
                    <div className="flex-none md:w-32 w-24 aspect-auto mx-auto">
                      <img src={addOn.badge} alt="ProductInfo" />
                    </div>

                    <div className="md:space-y-8 space-y-4 w-full md:text-lg text-md">
                      <div className="md:space-y-2 space-y-1 text-center md:text-left">
                        <div className="font-bold md:text-xl text-lg">
                          {addOn.name}
                        </div>
                        <div>
                          {t("packagePage.pricePerMonth", {
                            price: addOn.price,
                          })}
                        </div>
                        <div>
                          <Trans i18nKey={addOn.description} />
                        </div>
                      </div>
                      <div>
                        <div className="flex flex-wrap justify-between">
                          {addOn.features.map((feature) => (
                            <div className="text-left flex items-center w-full sm:w-1/2 md:w-1/3 space-x-2 md:pb-4 pb-2 leading-tight">
                              <div className="flex-none">
                                <FcOk />
                              </div>
                              <span className="ml-1">{feature}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-sm text-center pt-4">
                      {t("packagePage.gst")}
                    </p>
                    {countryCode === "GB" &&
                      addOn.name === t("packagePage.regulrName") && (
                        <p className="text-sm text-center pt-4">
                          {t("packagePage.regulrComingSoon")}
                        </p>
                      )}
                  </div>
                </div>
              );
            })}

            {showHardwareQuote ? (
              <HardwareItems preSignUpJourney={preSignUpJourney} />
            ) : (
              <div className="border-solid border-2 border-gray-400 rounded-lg md:p-8 p-4 md:text-lg text-md">
                <div className="md:flex md:space-x-8 space-y-4 text-center">
                  <div className="flex md:flex-row flex-col my-auto space-y-4 md:space-x-8">
                    <div className="flex-none md:w-40 w-56 mx-auto">
                      <img src={hardware} alt="ProductInfo" />
                    </div>
                    <div
                      className="my-auto text-marketing-300 underline"
                      onClick={goToHardwareForm}
                    >
                      {t("packagePage.goToHardwareForm")}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-full md:p-16 py-8 px-4 space-y-8">
        <CallToActionButtons
          preSignUpJourney={preSignUpJourney}
          analyticsFinishJourney={analyticsFinishJourney}
          sendQuote={sendQuote}
        />
      </div>

      <div className="mx-auto md:w-2/3 md:p-16 md:space-y-8 space-y-4 px-4 justify-center md:text-lg text-md">
        <div className="md:space-y-8 space-y-4">
          <p>
            <Trans
              i18nKey={"packagePage.returnToPosboss"}
              components={{
                ReturnLink: (
                  <a
                    className="text-marketing-300 underline"
                    href="https://www.posbosshq.com/"
                    onClick={() =>
                      analyticsFinishJourney(
                        "Return to posBoss",
                        "on preview package"
                      )
                    }
                    target="_blank"
                  />
                ),
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default PackagePreview;
