import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { setLanguage } from "./i18n";
import RadioQuestion from "./RadioQuestion";
import Checkbox from "./Checkbox";
import {
  contactInformation,
  controlFeatures,
  countryCode,
  hardwareForm,
  hardwareSorted,
  menuItems,
  packagePreview,
  paperless,
  printers,
  regulr,
  serviceStyle,
  styleOfBusiness,
  terminalIntegration,
  venueName,
} from "./questions";
import { postJsonRequest, patchJsonRequest } from "./ApiRequest";
import VenueNameQuestion from "./VenueNameQuestion";
import PackagePreview from "./PackagePreview";
import HardwareForm from "./HardwareForm";
import HardwareSortedQuestion from "./HardwareSortedQuestion";
import ContactInformation from "./ContactInformation";

//`gatsby-plugin-google-gtag` only works in production.
// In production return gtag but if it doesn't exist return an empty function
// to not break api access
// In order for this to work in development we
const fakeGtag = (event, end_journey, options) => {};
const getGtag = () =>
  typeof window !== "undefined" && window.gtag ? window.gtag : fakeGtag;

const savePreSignUpJourney = async ({ uuid, ...params }) => {
  const { body } = uuid
    ? await patchJsonRequest({
        url: `${process.env.GATSBY_POSBOSS_URL}/pre_sign_up_journeys/${uuid}`,
        body: params,
      })
    : await postJsonRequest({
        url: `${process.env.GATSBY_POSBOSS_URL}/pre_sign_up_journeys`,
        body: params,
      });

  return body;
};

const sendHardwareEstimate = async ({ uuid }) => {
  await postJsonRequest({
    url: `${process.env.GATSBY_POSBOSS_URL}/pre_sign_up_journeys/${uuid}/send_plans_quote`,
  });
};

const localesForCountry = {
  NZ: "en-NZ",
  AU: "en-AU",
  GB: "en-GB",
};

const Questionnaire = () => {
  const gtag = getGtag();
  const { t } = useTranslation();

  const [country, setCountry] = useState("NZ");
  const [page, setPage] = useState(venueName.id);
  const [preSignUpJourney, setPreSignUpJourney] = useState();

  const [journeyProgress, setJourneyProgress] = useState({
    totalQuestions: 11,
    currentQuestion: 1,
  });

  const updateProgress = (additionalQuestions) =>
    setJourneyProgress({
      ...journeyProgress,
      currentQuestion: journeyProgress.currentQuestion + 1,
      totalQuestions:
        journeyProgress.totalQuestions + (additionalQuestions || 0),
    });

  const analyticsContinueJourney = (response) => {
    Object.entries(response).map(([key, value]) => {
      gtag("event", key.toString(), {
        //Which question the user was answering
        event_category: "continue_journey",
        event_action: value.toString(), //Their selected options
      });
    });
  };

  const goToNextQuestion = (nextQuestion) => {
    updateProgress(nextQuestion.questionsAddedToJourney);
    setPage(nextQuestion.id);
  };

  const goToPreviousQuestion = (question) => {
    setPage(question.getPreviousQuestion());

    setJourneyProgress({
      ...journeyProgress,
      currentQuestion: journeyProgress.currentQuestion - 1,
    });

    if (question === hardwareForm) {
      setJourneyProgress({
        ...journeyProgress,
        currentQuestion: journeyProgress.currentQuestion - 1,
        totalQuestions: journeyProgress.totalQuestions - 1,
      });
    }
  };

  const onQuestionCompleted = (question) => async (response) => {
    const body = await savePreSignUpJourney({
      ...preSignUpJourney,
      ...response,
    });
    setPreSignUpJourney(body);
    goToNextQuestion(question.getNextQuestion(response));
    analyticsContinueJourney(response);
  };

  const goToHardwareForm = async () => {
    const body = await savePreSignUpJourney({
      ...preSignUpJourney,
      hardware_sorted: false, // Conditional to display hardware information on package preview
    });
    setPreSignUpJourney(body);
    goToNextQuestion(hardwareForm);
    setJourneyProgress({ totalQuestions: 1, currentQuestion: 1 });
  };

  const onContactInformationSaved = async (response) => {
    await onQuestionCompleted(contactInformation)(response);
    await sendHardwareEstimate(preSignUpJourney);
  };

  const onCountryCodeSaved = (question) => async (response) => {
    await onQuestionCompleted(question)(response);
    await i18n.changeLanguage(
      setLanguage(localesForCountry[response.country_code])
    );
    await setCountry(response.country_code);
  };

  return (
    <div>
      {page === venueName.id && (
        <VenueNameQuestion
          label={venueName.getProps(t)}
          onCompletion={onQuestionCompleted(venueName)}
          journeyProgress={journeyProgress}
        />
      )}

      {page === countryCode.id && (
        <RadioQuestion
          question={countryCode}
          onCompletion={onCountryCodeSaved(countryCode)}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(countryCode)}
        />
      )}

      {page === styleOfBusiness.id && (
        <RadioQuestion
          question={styleOfBusiness}
          venueName={venueName}
          onCompletion={onQuestionCompleted(styleOfBusiness)}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(styleOfBusiness)}
        />
      )}

      {page === serviceStyle.id && (
        <RadioQuestion
          question={serviceStyle}
          venueName={venueName}
          onCompletion={onQuestionCompleted(serviceStyle)}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(serviceStyle)}
        />
      )}

      {page === menuItems.id && (
        <RadioQuestion
          question={menuItems}
          venueName={venueName}
          onCompletion={onQuestionCompleted(menuItems)}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(menuItems)}
        />
      )}

      {page === controlFeatures.id && (
        <Checkbox
          question={controlFeatures}
          onCompletion={onQuestionCompleted(controlFeatures)}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(controlFeatures)}
        />
      )}

      {page === terminalIntegration.id && (
        <RadioQuestion
          question={terminalIntegration}
          onCompletion={onQuestionCompleted(terminalIntegration)}
          journeyProgress={journeyProgress}
          country={country}
          goToPreviousQuestion={() => goToPreviousQuestion(terminalIntegration)}
        />
      )}

      {page === regulr.id && (
        <RadioQuestion
          question={regulr}
          onCompletion={onQuestionCompleted(regulr)}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(regulr)}
        />
      )}

      {page === paperless.id && (
        <RadioQuestion
          question={paperless}
          onCompletion={onQuestionCompleted(paperless)}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(paperless)}
        />
      )}

      {page === printers.id && (
        <RadioQuestion
          question={printers}
          onCompletion={onQuestionCompleted(printers)}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(printers)}
        />
      )}

      {page === hardwareSorted.id && (
        <HardwareSortedQuestion
          question={hardwareSorted}
          onCompletion={onQuestionCompleted(hardwareSorted)}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(hardwareSorted)}
        />
      )}

      {page === hardwareForm.id && (
        <HardwareForm
          onCompletion={onQuestionCompleted(hardwareForm)}
          preSignUpJourney={preSignUpJourney}
          journeyProgress={journeyProgress}
          goToPreviousQuestion={() => goToPreviousQuestion(hardwareForm)}
        />
      )}

      {page === packagePreview.id && (
        <PackagePreview
          preSignUpJourney={preSignUpJourney}
          sendQuote={() => goToNextQuestion(contactInformation)}
          goToHardwareForm={() => goToHardwareForm()}
          goToPreviousQuestion={() => goToPreviousQuestion(packagePreview)}
        />
      )}

      {page === contactInformation.id && (
        <ContactInformation
          onCompletion={onContactInformationSaved}
          journeyProgress={journeyProgress}
        />
      )}
    </div>
  );
};

export default Questionnaire;
