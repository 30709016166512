import React from "react";
import posBossLogo from "./images/posBossLogo.png";

const NavBar = () => {
  return (
    <div className="p-4 flex bg-white space-x-4">
      <h1 className="text-4xl flex mx-auto text-center font-roboto text-bold">
        <a href="https://www.posbosshq.com">
          <img className="w-1/2 mx-auto" src={posBossLogo} alt="posBoss-logo" />
        </a>
      </h1>
    </div>
  );
};

export default NavBar;
