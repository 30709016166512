import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "./Button";
import TitleQuestion from "./TitleQuestion";

const Name = ({ onCompletion, journeyProgress }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();

  const onSubmit = (response) => {
    onCompletion(response);
  };

  return (
    <div className="text-center flex-col md:text-lg text-md">
      <TitleQuestion
        question={{ label: t("venueName.intro") }}
        journeyProgress={journeyProgress}
      />
      <div className="flex space-y-8 justify-center ">
        <form
          className="flex-col justify-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col md:p-16 p-8 space-y-4">
            <input
              className="flex border border-black rounded w-60 py-2 px-4 leading-tight"
              type="text"
              placeholder={t("venueName.placeholder")}
              {...register("venue_name")}
            />
            <a
              onClick={() => onSubmit({ venue_name: "your venue" })}
              className="text-marketing-300 py-2 cursor-pointer"
            >
              {t("venueName.iDontKnow")}
            </a>
          </div>
          <div className="flex-col flex items-center space-y-4">
            <Button type="submit">{t("button.next")}</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Name;
