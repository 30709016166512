import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";

const ResourceStates = {
  loaded: "LOADED",
  unloaded: "UNLOADED",
  loading: "LOADING",
  error: "ERROR",
  dirty: "DIRTY",
};

export const useSubmitButton = ({ errorTimeout, ...opts } = {}) => {
  const [submissionState, setSubmissionState] = useState(ResourceStates.loaded);
  const trackSubmit = (onSubmit) => async (data) => {
    try {
      setSubmissionState(ResourceStates.loading);
      const response = await onSubmit(data);
      setSubmissionState(ResourceStates.loaded);
      return response;
    } catch (error) {
      console.error(error);
      setSubmissionState(ResourceStates.error);
      setTimeout(
        () => setSubmissionState(ResourceStates.loaded),
        errorTimeout || 1000
      );
    }
  };

  return [submissionState, trackSubmit];
};

export const SubmitButton = ({
  submissionState,
  request,
  children,
  loadingMessage,
  errorMessage,
  ...props
}) => {
  const { t } = useTranslation();
  const state = submissionState;

  return (
    <Button {...props}>
      {state === ResourceStates.loaded && children}
      {state === ResourceStates.loading && (
        <React.Fragment>
          {loadingMessage || t("actions.saving")}{" "}
        </React.Fragment>
      )}
      {state === ResourceStates.error &&
        (errorMessage || (
          <React.Fragment>{t("actions.savingFailed")}</React.Fragment>
        ))}{" "}
    </Button>
  );
};

export default SubmitButton;
