import React from "react";
import TitleQuestion from "./TitleQuestion";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "./Button";

const HardwareForm = ({
  onCompletion,
  preSignUpJourney,
  journeyProgress,
  goToPreviousQuestion,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const showPaperless = preSignUpJourney.interested_in_paperless;
  const showPrinters = preSignUpJourney.printer_setup !== "no_printers";

  const onSubmit = async (response) => {
    await onCompletion(response);
  };

  return (
    <div className="text-center flex-col md:text-lg text-md">
      <TitleQuestion
        question={{ label: t("hardwareForm.intro") }}
        journeyProgress={journeyProgress}
        goToPreviousQuestion={goToPreviousQuestion}
      />
      <div className="flex justify-center">
        <form
          className="flex-col justify-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col tmd:p-16 p-8 space-y-4">
            <div className="flex flex-row items-center space-x-4">
              <input
                className="flex border border-black rounded w-20 py-2 px-4 leading-tight text-right"
                type="number"
                defaultValue={1}
                min={0}
                max={100}
                {...register("posboss_devices")}
              />
              <label>{t("hardwareForm.posBossDevices_question")}</label>
            </div>

            {showPaperless && (
              <div className="flex flex-row items-center space-x-4">
                <input
                  className="flex border border-black rounded w-20 py-2 px-4 leading-tight text-right"
                  type="number"
                  defaultValue={1}
                  min={0}
                  max={100}
                  {...register("paperless_devices")}
                />
                <label>{t("hardwareForm.paperlessDevices_question")}</label>
              </div>
            )}

            {showPrinters && (
              <div className="flex flex-row items-center space-x-4">
                <input
                  className="flex border border-black rounded w-20 py-2 px-4 leading-tight text-right"
                  type="number"
                  defaultValue={1}
                  min={0}
                  max={100}
                  {...register("printers")}
                />
                <label>{t("hardwareForm.printers_question")}</label>
              </div>
            )}

            <div className="flex flex-row items-center space-x-4">
              <input
                className="flex border border-black rounded w-20 py-2 px-4 leading-tight text-right"
                type="number"
                defaultValue={1}
                min={0}
                max={100}
                {...register("cash_drawers")}
              />
              <label>{t("hardwareForm.cashDrawers_question")}</label>
            </div>

            <div className="flex flex-row items-center space-x-4">
              <input
                className="flex border border-black rounded w-20 py-2 px-4 leading-tight text-right"
                type="number"
                defaultValue={1}
                min={0}
                max={100}
                {...register("ipad_stands")}
              />
              <label>{t("hardwareForm.ipadStands_question")}</label>
            </div>
          </div>

          <div className="flex-col flex items-center space-y-4 mt-16">
            <Button type="submit">{t("button.next")}</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HardwareForm;
