import React from "react";
import { useTranslation } from "react-i18next";
import hardware from "./images/hardware.png";

const HardwareItems = ({ preSignUpJourney }) => {
  const { t } = useTranslation();

  const countryCode = preSignUpJourney.country_code;
  const cashDrawers = +preSignUpJourney.cash_drawers || 0;
  const ipadStands = +preSignUpJourney.ipad_stands || 0;
  const paperlessDevices = +preSignUpJourney.paperless_devices || 0;
  const posbossDevices = +preSignUpJourney.posboss_devices || 0;
  const printerHardware = +preSignUpJourney.printers || 0;

  const hardwareItems = [
    {
      name: t("packagePage.printerHardware"),
      count: printerHardware,
      price: { NZ: 589, AU: 380, GB: 239.99 },
    },
    {
      name: t("packagePage.posbossIpad"),
      count: posbossDevices,
      price: { NZ: 649, AU: 549, GB: 369 },
    },
    {
      name: t("packagePage.paperlessIpad"),
      count: paperlessDevices,
      price: { NZ: 649, AU: 549, GB: 369 },
    },
    {
      name: t("packagePage.ipadStand"),
      count: ipadStands,
      price: { NZ: 165, AU: 165, GB: 89.99 },
    },
    {
      name: t("packagePage.cashDrawer"),
      count: cashDrawers,
      price: { NZ: 129, AU: 109, GB: 180 },
    },
  ];

  return (
    <div className="border-solid border-2 border-gray-400 rounded-lg md:p-8 p-4 md:text-lg text-md">
      <div className="md:flex md:space-x-8 space-y-4 text-left">
        <div className="my-auto">
          <div className="flex-none md:w-40 w-56 mx-auto">
            <img src={hardware} alt="ProductInfo" />
          </div>
        </div>

        <div className="md:space-y-8 space-y-4 w-full md:text-lg text-md">
          <div className="md:space-y-2 space-y-1 text-center md:text-left">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="font-bold md:text-xl text-lg text-left">
                    {t("packagePage.hardwareEstimate")}
                  </th>
                  <th className="font-bold md:text-xl text-lg text-right">
                    {t("packagePage.pricePerUnit")}
                  </th>
                  <th className="font-bold md:text-xl text-lg text-right">
                    {t("packagePage.totalPrice")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {hardwareItems.map(({ name, count, price }) => (
                  <tr>
                    <td className="text-left">
                      {t("packagePage.hardwareItems", {
                        name: name,
                        count: count,
                      })}
                    </td>

                    <td className="text-right">
                      {t("packagePage.hardwarePrice", {
                        price: price[countryCode].toFixed(2),
                      })}
                    </td>

                    <td className="text-right">
                      {t("packagePage.hardwarePrice", {
                        price: (count * price[countryCode]).toFixed(2),
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                <tr>
                  <th colSpan="2" />
                  <th colSpan="1" className="text-right">
                    {t("packagePage.hardwarePrice", {
                      price: hardwareItems
                        .reduce(
                          (total, { price, count }) =>
                            total + price[countryCode] * count,
                          0
                        )
                        .toFixed(2),
                    })}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      {countryCode === "NZ" && (
        <a
          target="_blank"
          href="https://www.posbosshq.com/posboss-hardware"
          className="italic text-sm hover:underline"
        >
          {t("packagePage.availableOnPosbossHardware")}
        </a>
      )}
    </div>
  );
};

export default HardwareItems;
