import React from "react";
import { Trans } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";
import jonnyAvatar from "./images/jonnnyAvatar.png";

const TitleQuestion = ({
  question,
  previousQuestionResponses,
  journeyProgress,
  goToPreviousQuestion,
}) => {
  return (
    <div className="space-y-8 text-center">
      <div className="bg-gradient-to-r md:w-auto md:p-8 p-4 from-blue-300 to-green-200">
        {journeyProgress.currentQuestion !== 1 && (
          <div className="cursor-pointer" onClick={goToPreviousQuestion}>
            <FaChevronLeft />
          </div>
        )}

        <div className="h-28">
          <img className="mx-auto h-full" src={jonnyAvatar} alt="Avatar" />
        </div>
        {journeyProgress && (
          <div className="pt-2 md:text-sm text-xs">
            <Trans
              i18nKey={"common.questionCount"}
              values={{
                questionNumber: journeyProgress.currentQuestion,
                totalQuestions: journeyProgress.totalQuestions,
              }}
            />
          </div>
        )}
      </div>
      <h1 className="md:text-xl text-md mx-auto max-w-screen-sm">
        <Trans
          i18nKey={question.label}
          values={{ previousQuestionResponses }}
          components={{
            Small: <p className="text-base" />,
          }}
        />
      </h1>
    </div>
  );
};

export default TitleQuestion;
