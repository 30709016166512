import React from "react";
import TitleQuestion from "./TitleQuestion";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SubmitButton, { useSubmitButton } from "./SubmitButton";

const ContactInformation = ({ onCompletion, journeyProgress }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (response) => {
    await new Promise((resolve) => setTimeout(resolve, 1500));
    await onCompletion(response);
  };

  const [submissionState, trackSubmit] = useSubmitButton();

  return (
    <div className="text-center flex-col md:text-lg text-md">
      <TitleQuestion
        question={{ label: "" }}
        journeyProgress={journeyProgress}
      />
      <div className="flex justify-center">
        <form
          className="flex-col justify-center"
          onSubmit={handleSubmit(trackSubmit(onSubmit))}
        >
          <div className="flex flex-col tmd:p-16 p-8 space-y-8">
            <div className="flex flex-col items-center space-y-2 justify-between">
              <label>{t("contactInfo.firstName_question")}</label>
              <div className="flex flex-row space-x-8">
                <input
                  className="flex border border-black rounded w-48 py-2 px-4 leading-tight"
                  type="text"
                  required
                  placeholder={t("contactInfo.firstName_placeholder")}
                  {...register("first_name")}
                />

                <input
                  className="flex border border-black rounded w-48 py-2 px-4 leading-tight"
                  type="text"
                  required
                  placeholder={t("contactInfo.lastName_placeholder")}
                  {...register("last_name")}
                />
              </div>
            </div>

            <div className="flex flex-col items-center space-y-2 justify-between">
              <label>{t("contactInfo.email_question")}</label>
              <input
                className="flex border border-black rounded w-60 py-2 px-4 leading-tight"
                type="email"
                required
                placeholder={t("contactInfo.email_placeholder")}
                {...register("email")}
              />
            </div>
          </div>

          <div className="flex-col flex items-center space-y-4">
            <SubmitButton
              submissionState={submissionState}
              loadingMessage={t("contactInfo.emailingEstimate")}
              errorMessage={t("contactInfo.emailingEstimateFailed")}
              type="submit"
            >
              {t("contactInfo.emailQuote")}
            </SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactInformation;
