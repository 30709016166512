import React from "react";
import "../i18n";
import NavBar from "../NavBar";
import Questionnaire from "../Questionnaire";

const Index = () => {
  return (
    <div className="pb-16 leading-snug">
      <div>
        <NavBar />
      </div>

      <div>
        <Questionnaire />
      </div>
    </div>
  );
};

export default Index;
