import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import en_nz from "./locales/en-NZ.json";
import en_au from "./locales/en-AU.json";
import en_gb from "./locales/en-GB.json";

let locale;
export const setLanguage = (userLocale) => {
  return (locale = userLocale);
};

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en,
  },
  "en-NZ": {
    translation: en_nz,
  },
  "en-AU": {
    translation: en_au,
  },
  "en-GB": {
    translation: en_gb,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    /**
     * language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
     * you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
     * if you're using a language detector, do not define the lng option
     **/
    lng: locale,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
