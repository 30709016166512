import React from "react";

const Button = ({ children, ...props }) => {
  return (
    <button
      className="bg-marketing-500 flex hover:bg-marketing-400 text-white py-2 px-12 rounded-lg"
      {...props}
    >
      {children}
    </button>
  );
};
export default Button;
