export const terminalIntegration_options = (t) => ({
  NZ: {
    smartpay: {
      value: "smart_connect",
      label: t("terminalIntegration.smartpay"),
    },
    payclip: {
      value: "universal_verifone",
      label: t("terminalIntegration.payclip"),
    },
    verifone: {
      value: "verifone_payments",
      label: t("terminalIntegration.verifone"),
    },
    windcave: {
      value: "payment_express",
      label: t("terminalIntegration.windcave"),
    },
    tyro: {
      value: "tyro",
      label: t("terminalIntegration.tyro"),
    },
    square: {
      value: "square_payments",
      label: t("terminalIntegration.square"),
    },
    notInterested: {
      value: "not_interested",
      label: t("terminalIntegration.notInterested"),
    },
    inputManually: {
      value: "manual_input",
      label: t("terminalIntegration.inputManually"),
    },
    notSure: {
      value: "not_sure",
      label: t("terminalIntegration.notSure"),
    },
  },
  AU: {
    smartpay: {
      value: "smart_connect",
      label: t("terminalIntegration.smartpay"),
    },
    windcave: {
      value: "payment_express",
      label: t("terminalIntegration.windcave"),
    },
    tyro: {
      value: "tyro",
      label: t("terminalIntegration.tyro"),
    },
    square: {
      value: "square_payments",
      label: t("terminalIntegration.square"),
    },
    notInterested: {
      value: "not_interested",
      label: t("terminalIntegration.notInterested"),
    },
    inputManually: {
      value: "manual_input",
      label: t("terminalIntegration.inputManually"),
    },
    notSure: {
      value: "not_sure",
      label: t("terminalIntegration.notSure"),
    },
  },
  GB: {
    square: {
      value: "square_payments",
      label: t("terminalIntegration.square"),
    },
    windcave: {
      value: "payment_express",
      label: t("terminalIntegration.windcave"),
    },
    notInterested: {
      value: "not_interested",
      label: t("terminalIntegration.notInterested"),
    },
    inputManually: {
      value: "manual_input",
      label: t("terminalIntegration.inputManually"),
    },
    notSure: {
      value: "not_sure",
      label: t("terminalIntegration.notSure"),
    },
  },
});
