import React from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import TitleQuestion from "./TitleQuestion";
import hardware from "./images/hardware.png";

const HardwareSortedQuestion = ({
  question,
  onCompletion,
  journeyProgress,
  goToPreviousQuestion,
}) => {
  const { t } = useTranslation();

  return (
    <div className="text-center flex-col md:text-lg text-md space-y-16">
      <TitleQuestion
        question={question.getProps(t).question}
        journeyProgress={journeyProgress}
        goToPreviousQuestion={goToPreviousQuestion}
      />

      <div className="flex-none md:w-96 w-72 mx-auto">
        <img src={hardware} alt="hardware" />
      </div>

      <div className="flex justify-center">
        <div className="flex-row flex items-center space-x-16">
          <Button
            onClick={() => onCompletion({ hardware_sorted: true })}
            className="text-marketing-500 flex border-marketing-500 border-2 bg-white py-2 px-12 rounded-lg hover:shadow-md"
          >
            {t("hardwareSorted.no")}
          </Button>
          <Button onClick={() => onCompletion({ hardware_sorted: false })}>
            {t("hardwareSorted.yes")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HardwareSortedQuestion;
